<style scoped>
.pagetitle {
  padding-bottom: 20px;
}
</style>

<template>
  <v-app light>
    <v-container fluid>
      <section>
        <v-layout column wrap class="my-12" align-center justify-center row>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center justify-center>
                <v-flex xs11 md11>
                    <div class="text-center">
                    <h1 class="pagetitle">Erklärung zur Barrierefreiheit</h1>
                    </div>
                  <div class="text-left">
                    <h2 class="headline">Nicht barrierefreie Inhalte</h2>
                    <p class="text-justify">
                        Diese in der Website eingebundenen Inhalte sind noch nicht barrierefrei:
                        <ul>
                            <li>PDF-Dokumente: Aufgrund der großen Menge konnten diese bislang nicht in ein barrierefreies Format überführt werden. Sie werden sukzessive angepasst.</li>
                            <li>Videos: Die Bereitstellung mit einer Audiodeskription konnte bislang nicht durchgängig realisiert werden. Hieran wird gearbeitet.</li>
                            <li>Bilder: Die Bereitstellung mit alternativen Textbeschreibungen konnte bislang nicht durchgängig realisiert werden. Für bereits veröffentlichte Bilder wird das schrittweise nachgeholt bzw. für neu eingebaute Bilder berücksichtigt.</li>
                        </ul>
                    </p>
                    <p class="text-justify">
                        Barrierefreie Alternativen:
                        Nutzen Sie bitte dieses <a target="_blank" href="https://www.fh-dortmund.de/de/_diverses/kontaktformular_barrierefreiheit.php">Feedback-Formular</a>.
                    </p>

                    <h2 class="headline">Feedback und Kontaktangaben</h2>
                    <p class="text-justify">
                      Sie können uns Mängel bei der Einhaltung der Anforderungen
                      an die Barrierefreiheit mitteilen und Informationen zu
                      Inhalten anfordern, die von der Richtlinie ausgenommen
                      sind.
                    </p>
                    <p class="text-justify">
                      Kontakt: Siehe Impressum
                      
                    </p>
                    <p class="text-justify">
                      Feedback:
                      <br />Wenn Sie Kontakt zur Fachhochschule Dortmund
                      aufnehmen möchten, nutzen Sie bitte unser
                      <a
                      target="_blank"
                        href="https://www.fh-dortmund.de/de/_diverses/kontaktformular_barrierefreiheit.php"
                        >Feedback-Formular</a
                      >. <br />Bitte geben Sie bei der Nachricht das Stichwort
                      "Barrierefreiheit" an und teilen uns zusammen mit der
                      Adresse (URL) mit, auf welchen Inhalt Sie sich beziehen.
                    </p>

                    <h2 class="headline">Durchsetzungsverfahren</h2>
                    <p class="text-justify">
                      Sollten Sie auf Mitteilungen oder Anfragen zur
                      Barrierefreiheit innerhalb von sechs Wochen keine
                      zufriedenstellende Antwort erhalten haben, können Sie die
                      Ombudsstelle für barrierefreie Informationstechnik
                      einschalten. Sie ist der oder dem Beauftragten für die
                      Belange der Menschen mit Behinderung nach § 11 des
                      Behindertengleichstellungsgesetzes Nordrhein-Westfalen
                      zugeordnet. Wenn Sie ein Ombudsverfahren wünschen, füllen
                      Sie bitte den entsprechenden <a target="_blank" href="https://www.mags.nrw/sites/default/files/asset/document/antrag_ombudsverfahren_0.pdf">Antrag</a> aus und senden ihn per
                      E-Mail an die Ombudsstelle:
                      ombudsstelle-barrierefreie-it@mags.nrw.de
                    </p>
                    <p class="text-justify">
                        Ombudsstelle für barrierefreie Informationstechnik
                        <br />des Landes Nordrhein-Westfalen bei der Landesbeauftragten
                        <br />für Menschen mit Behinderungen
                        <br />Fürstenwall 25
                        <br />40219 Düsseldorf
                    </p>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
    </v-container>
  </v-app>
</template>

 <script>
export default {
  name: "Accessibility",

  components: {},

  data: () => ({}),
  computed: {
    year: function () {
      return 1900 + new Date().getYear();
    },
  },
};
</script>